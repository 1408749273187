import React, { useContext } from 'react'
import { surveyPicker } from '../App'
import './sidebar.css'
import {Translator, Translate} from 'react-auto-translate';

function Sidebar() {

  const {satisfactionSurvey, setSatisfactionSurvey, transLang} = useContext(surveyPicker)
  
  return (
    <>
    <Translator
    from='en'
    to={transLang}
    googleApiKey='AIzaSyDvXXS7riq-RvQtmZLLkxkyZoo0LY6XGHc'
  >
    <div id="sidebar" className="sidebar">
    {/*begin sidebar scrollbar --> */}
    <div data-scrollbar="true" data-height="100%" style={{overflow: 'hidden', width: 'auto', height: '100%'}}> 
    {/*<!-- begin sidebar nav --> */}
    <ul className="nav">
    <li className="active">
    <a href="/core/dashboard">
    <i className="fa fa-th-large"></i>
    <span>Dashboard</span>
    </a>
    </li>

    
    <li style={satisfactionSurvey ? {display: ''} : {display: 'none'}}>
    <a href="/core/respondents">
    <i className="fa fa-users"></i>
    <span><Translate>All Respondents</Translate></span> 
    </a>
    </li>

    <li>
    <a href="/core/qrcode">
    <i class="fa-solid fa-qrcode"></i>
    <span><Translate>Survey QR Code</Translate></span> 
    </a>
    </li>
    </ul>
    {/* <!-- end sidebar nav --> */}
    </div>
   {/*  <!-- end sidebar scrollbar --> */}
    </div>
    <div className="sidebar-bg"></div>
    </Translator>
    </>
  )
}

export default Sidebar