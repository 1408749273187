import React from 'react'
import { useLocation, Navigate, Outlet} from 'react-router-dom'

const Authorizer = ({children}) => {
    const location = useLocation();
    const email = localStorage.getItem('email');
    const isLoggedIn = localStorage.getItem('loggedIn');

    return(
        isLoggedIn ? <Outlet /> : <Navigate to="/login" state={{ from: location}} replace />
    )
}

export default Authorizer
