import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './question.css'

function RespondentSurvey() {

    const { id } = useParams();
    const [surveys, setSurveys] = useState([]);
    const { name } = useParams();


    useEffect(()=>{

        axios({
          method: "get",
          url: `https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/patient_survey_question_answer/${id}`,
          // url: `https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/patient_questions_answers/${id}`,
        }).then((res) => {
          setSurveys(res.data);
          //console.log(res.data[0].id)
        });

    }, [])

    const surveyList = surveys.map(survey => {
        return(
            <>

            
            <tr style={{cursor: "pointer"}}>
            <td style={{padding: '10px'}}>{survey._id}</td>
            <td style={{padding: '10px'}}>{survey.date_added}</td>
            <td style={{padding: '10px'}}><button class="btn btn-primary btn-sm" onClick={()=>window.location = `/core/respondent-survey/${id}/${survey._id}`}>View</button></td></tr>
           

            </>
        )
    })





  return (
    <section>
    <div id="content" className="content">
    {/* <!-- begin breadcrumb --> */}
    <ol className="breadcrumb pull-right">
            <li className="breadcrumb-item"><a href="/core/">Home</a></li>
            <li className="breadcrumb-item"><a href="/core/respondents">Respondents</a></li>
            <li className="breadcrumb-item"><a href="#">Survey</a></li>
        </ol>
        {/* <!-- end breadcrumb -->*/}

        <div className='mt-5'>        
        <h2>Surveys by {name}</h2>

        <div className='question mt-3' style={{width: '60%'}}>
        <table className="table table-striped table-bordered">
        <thead>
        <tr>
            <th className="text-nowrap">Survey ID</th>
            <th className="text-nowrap">Date</th>
            <th className="text-nowrap"></th>
        </tr>
        </thead>
        <tbody>
        {surveyList}
        </tbody>
        </table>
        </div>
        </div>
    </div>
    </section>
  )
}

export default RespondentSurvey