import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

export const DateContext = createContext({});

export const DateProvider = ({ children }) => {

  const [sortedData, setSortedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trafficData, setTrafficData] = useState([]);

  useEffect(() => {
    axios({
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
      url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/current_sorted_survey_data',
    }).then(res => {
      setLoading(false)
      setSortedData(res.data);
      console.log(res.data)
    }).catch(err => {
      console.log(err);
    });

    /* get the traffic source for current date*/
    let currentDate = new Date().toJSON().slice(0, 10);
    console.log(currentDate); // "2022-06-17"

    axios({
      method: 'get',
      url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_access_method_analytics_date_range/' + currentDate + '/' + currentDate,
      headers: {},
    }).then(res => {
      console.log(res.data)
      setTrafficData(res.data);
      console.log(trafficData)
    })
    /* end of get traffic source */

  }, []);

  const [displayToggle, setDisplayToggle] = useState(false);


  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
  ]);


  return (
    <DateContext.Provider
      value={{
        state,
        setState,
        sortedData,
        setSortedData,
        displayToggle,
        setDisplayToggle,
        trafficData,
        setTrafficData,
        loading,
        setLoading,
        toggleCalendar: async () => {
          setDisplayToggle(!displayToggle);
        },
        showSelection: async (item) => {
          let range = [item.selection]
          setLoading(false)
          setState(range)

          let cloneDateState = [...range]; // clone the state 

          let sd = cloneDateState[0].startDate;
          let ed = cloneDateState[0].endDate;

          let start_date = moment(sd).format("YYYY-MM-DD");
          let end_date = moment(ed).format("YYYY-MM-DD");

          //The api below now called in the satisfactionSurvey component when the update botton is clicked
          /* axios({
            method: 'get',
            headers: { 'Content-Type': 'application/json'},
            url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_by_date_range/'+start_date+'/'+end_date,
              }).then(res=>{
                let data = res.data
                  
                    axios({
                      method: 'post',
                      headers: { 'Content-Type': 'application/json'},
                      url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/sorted_survey_data',
                      data: JSON.stringify(data),
                        }).then((response) => {
                          setDisplayToggle(false)
                          setSortedData(response.data)
                            setLoading(false)
                        }, (error) => {
                          console.log(error.message);
                        });

              }).catch(err=>{
                  console.log(err);
              });
*/
        }

      }}>
      {children}
    </DateContext.Provider>
  );
};
