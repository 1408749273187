import React from 'react'

export const ThankYou = () => {
  return (
    <div style={{textAlign: 'center'}}>
        <div className='alert-success p-4 mb-2'>
            Your response has been submitted. Thank you!
        </div>
    </div>
  )
}
