import React, { useContext, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { surveyPicker } from '../App';



function QRgenerator() {

  const {satisfactionSurvey, setSatisfactionSurvey} = useContext(surveyPicker);

  useEffect(()=>{
    setSatisfactionSurvey(false);
  }, [])

  const [qrValue, setQrValue] = useState(
    "https://cww-survey-engine-cb4e1344cff2.herokuapp.com/form/satisfaction-survey?utm_source=qrcode&utm_medium=qrcode"
  );

  // in a case where the admin want to enter a different url
  const handleOnChange = event => {
    const { value } = event.target;
    setQrValue(value);
  };

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrValue}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  
  return (
    <div className="content">
      <h3>This qrcode by default redirects users to the Patient Satisfation Survey form.</h3>


      <p>You can enter a different url here to redirect the user when qrcode is scanned</p>
      <input className="form-control" onChange={handleOnChange} placeholder="Enter the url" />
      <br />
      <QRCode
        id="qr-gen"
        value={qrValue}
        size={290}
        level={"H"}
        includeMargin={true}
      />
      <p>
        <button className="btn btn-secondary" type="button" onClick={downloadQRCode}>
          Download QR Code
        </button>
      </p>
    </div>
  );
}

export default QRgenerator