import axios from 'axios';
import React, { useState } from 'react'
import './renovation.css'
import { Helmet } from 'react-helmet';
import {Translator, Translate} from 'react-auto-translate';

function RenovationSurvey() {

  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [answer, setAnswer] = useState('')
  const [age, setAge] = useState('')
  const [gender, setGender] = useState('')
  const [lang, setTransLang] = useState('en')

  const setLang = (lang) => {
    setTransLang(lang)
}

  const onResponseChange = (e) => {
    setAnswer(e.target.value);
  }

  const onGenderChange = (e) => {
    setGender(e.target.value);
  }

  const onAgeChange = (e) => {
    setAge(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    //console.log(answer, gender, age)
    axios({
      method: 'post',
      url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/post_survey_data2',
      headers:{
        'Content-Type': 'application/json'
      },
      data: {
        data: {
          answer: answer, 
          gender: gender, 
          age: age
        }
      }
    }).then(res=>{
      console.log(res.data)
      if(res.status == 200){
        setErrorMsg('')
        //setSuccessMsg('Your response has been submitted. Thank you!')
        window.location = '/thank-you'
      }else{
        setErrorMsg('Something went wrong. Response not submitted.')
      }
    }).catch(err=>{
      setSuccessMsg('')
      setErrorMsg('Something went wrong. Response not submitted.')
      console.log(err);
    })
  }

  return (
    <>
    <Translator
    from='en'
    to={lang}
    googleApiKey='AIzaSyDvXXS7riq-RvQtmZLLkxkyZoo0LY6XGHc'
  >
    <Helmet>

    <title>Renovation Survey</title>
    <meta name="description" content="CWWCHC Building Renovation Survey" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
    
    
    <div className='wrapper'>
       <div className='survey-div renovation'>
            <div className='form-div'>

            <h3 className='text-center p-3'><Translate>The C.W. Williams Community Health Center Building  at 3333 Wilkinson Boulevard Charlotte, NC28208 Recontruction Survey</Translate></h3>

            <div className='my-2'>
            <span>Select Language</span>&nbsp; &nbsp;
         <select className='lang-select' onChange={(e)=>setLang(e.target.value)}>
                 <option value='en'>English</option>
                 <option value='es'>Spanish</option>
         </select>
            </div>

            <div style={errorMsg ? {display: 'block'} : {display: 'none'}} className='alert-warning p-4 mb-2'>
              {errorMsg}
            </div>

            <form onSubmit={onSubmit}>
                <div className='formgroup'>
                <div><Translate>Do you think the C.W. William Community Health Center building at 3333 Wilkinson Boulevard Charlotte, NC28208 should be rebuilt?</Translate></div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" required name="renovationAnswer" id="exampleRadios1" value="Yes" onChange={onResponseChange}/>
                  <label className="form-check-label" for="exampleRadios1">
                  <Translate>Yes</Translate>
                  </label>
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="radio" name="renovationAnswer" id="exampleRadios2" value="No" onChange={onResponseChange} />
                  <label className="form-check-label" for="exampleRadios2">
                  <Translate>No</Translate>
                  </label>
                </div>
                </div>


                <div className="form-group">
                <div><Translate>Gender</Translate></div>

                <div className="form-check">
                  <input className="form-check-input" type="radio" required name="gender" id="exampleRadios1" value="Male" onChange={onGenderChange}/>
                  <label className="form-check-label" for="gender">
                    <Translate>Male</Translate>
                  </label>
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="radio" name="gender" id="exampleRadios2" value="Female" onChange={onGenderChange}/>
                  <label className="form-check-label" for="gender">
                  <Translate>Female</Translate>
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div><Translate>Age</Translate></div>
                <select className="custom-select" aria-label="Select age group" onChange={onAgeChange} required>
                <option disabled selected>Select...</option>
                <option value="18-25">18-25</option>
                <option value="26-35">26-35</option>
                <option value="36-45">36-45</option>
                <option value="46-55">46-55</option>
                <option value="56-65">56-65</option>
                <option value="65+">65+</option>
              </select>
              </div>


                <div>
                <input type='submit' className='btn btn-primary btn-lg' value='Submit'/>
                </div>
                </form>
            
            </div>
        </div>
    </div>
    
    </Translator>
    </>
  )
}

export default RenovationSurvey
