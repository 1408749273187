import axios, { Axios } from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import './login.css'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import cwwlogo from './cww-logo.png';


function Login() {


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [loginError, setLoginError] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
  
    
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(()=>{
        localStorage.clear();
    }, [])

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
       /*  localStorage.setItem('loggedIn', true); */
        try{
        axios({
            method: 'post',
            url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/auth/signin',
            headers: { 'Content-Type': 'application/json'},
            data: {
                email: email,
                password: password,
            }
        }).then(res=>{
            if(res.data.status_code === 200){
   localStorage.setItem("loggedIn", true);
   localStorage.setItem("name", res.data.user.fullname);
   localStorage.setItem("email", res.data.user.email);
                setErrorMsg('');
                setSuccessMsg('Logging in...');
                window.location = ('/core/dashboard');
            }else{
                setLoading(false);
                setErrorMsg(res.data.message);
            }
        })
        }catch{
            alert('Unable to connect to server');
            setLoading(false);
        }
    
    }

    const loading = (
        <div className='loading'>
            
        </div>
    )
  return (
    <>
        <div className="login--page">
        <div className='login--section'>

            <form onSubmit={onSubmit}>

            <div className='login--div'>
            
            <div className='logo--section'>
                <div className='logo-div'>
                    <div className='logo'>
                        <img src={cwwlogo} alt='cwwchc logo' width="198" height="97"/>
                    </div>
                </div>
            </div>


            <div className='text-center mt-5'><h2>Survey Engine</h2></div>
            <span style={{color:'red'}}>{loginError}</span>

            <div className='alert-warning p-3' style={errorMsg ? {display: 'block'} : {display: 'none'}}>{errorMsg} </div>
            <div className='alert-success p-3' style={successMsg ? {display: 'block'} : {display: 'none'}}>{successMsg} </div>

            <div className="form-group">
            <label htmlFor="nickname">Email</label>
            <input type="email" value={email} autoComplete="off" name="nickname" onChange={onEmailChange} class="form-control" id="nickname" required aria-describedby="nicknameHelp" />
            <small id="emailHelp" className="form-text text-muted"></small>
            </div>

            <div className="form-group">
            <label htmlFor="nickname">Password</label>
            <input type="password" value={password} name="nickname" onChange={onPasswordChange} class="form-control" id="password" required minLength="2" maxLength="" aria-describedby="passwordHelp" />
            <small id="paswordHelp" className="form-text text-muted"></small>
            </div>

            <button className='btn btn-primary btn-login text-center'> { isLoading ? loading : 'Login'}</button>

            <div>
               {/*  <p className='pt-2 text-secondary' style={{fontSize: '12px'}}>Don't have an account? <Link to='/sign-up'>Click here to sign up</Link></p> */}
            </div>
            </div>
            </form>
        </div>
        </div>
    </>
  )
}

export default Login