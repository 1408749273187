import React from 'react'
import './dashboard.css'
import './renovation.css';
import PropTypes from 'prop-types'
import { Bar, Doughnut } from 'react-chartjs-2'
import { Translate } from 'react-auto-translate/lib/commonjs'

export const BarChart = ({title, data, options}) => {
  return (
    <div className="panel panel-inverse">
                <div className="panel-heading">
                    
                    <h4 className="panel-title"><Translate>{title}</Translate></h4>
                </div>
                <div className="panel-body">
                    <div id="nv-stacked-bar-chart" className="height-sm">
                        <Bar data={data} options={options} />
                    </div>
                </div>
     </div>
  )
}

export const DoughnutChart = ({title, data, options}) => {

    return (
      <div className="panel panel-inverse">
                  <div className="panel-heading">
                      
                      <h4 className="panel-title"><Translate>{title}</Translate></h4>
                  </div>
                  <div className="panel-body">
                      <div id="nv-stacked-bar-chart interactive-chart" className="height-sm">
                      <Doughnut data={data} options={options} />
                      </div>
                  </div>
       </div>
    )
  }

BarChart.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array,
    options: PropTypes.array,
}

DoughnutChart.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array,
    options: PropTypes.array,
}

//export default BarChart
