import React, { useContext } from 'react'
import './dashboard.css'
import 'chart.js/auto';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import SatisfactionSurvey from './SatisfactionSurvey';
import { surveyPicker } from '../App';
import RenovationDashboard from './RenovationDashboard';

/* import { SurveyPicker } from './Header'
import Header from './Header';
 */



function Dashboard() {

   /*  const { isSatisfactionSurvey } = useContext(Header); */

   const { satisfactionSurvey } = useContext(surveyPicker);

   //alert(satisfactionSurvey);


    const buildingSurvey = (
        <div className='content'>
            No data available for this survey. Check back
        </div>
    )

  return (
     
     (satisfactionSurvey) ? (<SatisfactionSurvey />) : <RenovationDashboard />
    
  )
}

export default Dashboard