import React, {createContext, useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';

export const RenovationDateContext = createContext({});

export const RenovationDateProvider = ({children}) => {

  const [sortedData, setSortedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [today, setToday] = useState(moment().utcOffset('+01:00').format('YYYY-MM-DD'));

  useEffect(()=>{
      axios({
          method: 'get',
          headers: { 'Content-Type': 'application/json'},
          url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/current_sorted_survey_data2',
        }).then(res=>{
          setLoading(false)
          setSortedData(res.data);
      }).catch(err=>{
          console.log(err);
      });
  }, []);

  const [displayToggle, setDisplayToggle] = useState(false);


  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);


  return (
    <RenovationDateContext.Provider
      value={{
        state,
        setState,
        sortedData,
        setSortedData,
        displayToggle,
        loading,
        setLoading,
        toggleCalendar: async () => {
          setDisplayToggle(!displayToggle);
        },
        showSelection: async (item) => {
          let range = [item.selection]
          setLoading(true)
          setState(range)
          
          let cloneDateState = [...range]; // clone the state 
          
          let sd = cloneDateState[0].startDate;
          let ed = cloneDateState[0].endDate;
       
          let start_date  = moment(sd).format("YYYY-MM-DD");
          let end_date  = moment(ed).format("YYYY-MM-DD");
      
              axios({
                method: 'get',
                headers: { 'Content-Type': 'application/json'},
                url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_by_date_range2/'+start_date+'/'+end_date,
                  }).then(res=>{

                    let data = res.data
                  

                        axios({
                          method: 'post',
                          headers: { 'Content-Type': 'application/json'},
                          url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/sorted_survey_data2',
                          data: JSON.stringify(data),
                            }).then((response) => {
                              setDisplayToggle(false)
                              setSortedData(response.data)
                              console.log(response.data)
                              setLoading(false)
                            }, (error) => {
                              console.log(error.message);
                            });

                  }).catch(err=>{
                      console.log(err);
                  });

      }
     
      }}>
      {children}
    </RenovationDateContext.Provider>
  );
};
