import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Header() {
  return(
  <>
  <Helmet>

<title>Patient Satisfaction Survey</title>
<meta name="description" content="CWWCHC Patient Satisfaction Survey" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
</Helmet>
  <div style={{textAlign: 'center', backgroundColor:''}}>
  <img src="https://www.cwwilliams.org/wp-content/uploads/2019/07/logo.png" title="CWWCHC Logo" alt="CWWCHC Logo"/>
  <h3>Provide feedback on the care you received</h3>
  <Link to='/form/satisfaction-survey/spanish'><button style={{cursor: 'pointer', border: 'none', padding: '8px 10px', borderRadius: '4px', marginBottom: '4px' }}>Show in Spanish</button></Link>
  </div>
  </>
  )
}

export default Header;
