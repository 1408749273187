import React, { useContext, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import 'chart.js/auto';
import './renovation.css';
import { RenovationDateContext } from './RenovationDateProvider';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { useReactToPrint } from "react-to-print";
import { dataLabel } from './SatisfactionSurvey';
import {Translator, Translate} from 'react-auto-translate';
import { surveyPicker } from '../App';
import { DoughnutChart } from './Charts';
import Cards from './Cards';

function RenovationDashboard() {

    const printRef = useRef(); //Ref for section/div to print

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
      });

     const {state, displayToggle, toggleCalendar, showSelection, sortedData, setSortedData} = useContext(RenovationDateContext);


     const dateRange = {...state[0]}

     //console.log(state);
 
     const startDate = (moment(dateRange.startDate).utcOffset('+01:00').format('YYYY-MM-DD'));
     const endDate = (moment(dateRange.endDate).utcOffset('+01:00').format('YYYY-MM-DD'));

    const [renovationSurveyData, setRenovationSurveyData] = useState([])

    const { transLang } = useContext(surveyPicker)

    useEffect(()=>{
        axios({
            method: 'get',
            url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_by_date_range2/'+startDate+'/'+endDate,
         //  url: 'http://delmita.herokuapp.com/api/survey_data2',
        }).then(res=>{
            setRenovationSurveyData(res.data);
            //setSortedData(dateRange.startDate);
            // setSortedData(res.data);
           // console.log(res.data)
        })

    }, [])

   // console.log(sortedData)

    const totalSurvey = sortedData.total_survey;
console.log("sortedData --- ", sortedData)
    const gender = {...sortedData.gender_analytics}
    const answer = {...sortedData.answer_analytics}
    const ageAnalytics = {...sortedData.age_analytics}

    const ageLabel = Object.keys(ageAnalytics);
    const ageValue = Object.values(ageAnalytics);

    const answerValue = Object.values(answer);

    

    /* const yesResponse = sortedData.answer_analytics['Yes'];
    const noResponse = sortedData.answer_analytics['No']; */

    const responseData = ({
        labels: ['No', 'Yes'],
          datasets: [{
              label: '',
              data: answerValue,
              backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
            ],
              borderWidth: 2
          }]
    });

    const responseOptions = ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
        title: {
            display: false,
            text: 'Age distribution',
        },
        datalabels: dataLabel,
        }
    });


    const ageChartData = ({
        labels: ageLabel,
          datasets: [{
              label: '',
              data: ageValue,
              backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
            ],
              borderWidth: 2
          }]
    });

    const ageChartoptions = ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
        title: {
            display: false,
            text: 'Age distribution',
        },
        datalabels: dataLabel,
        }
    });

    const buttonText = (startDate, endDate) => {
        if(startDate == endDate){
            return 'Today'
        }
        else{
            return (startDate + ' - ' + endDate)
        }
    }

  return (
    <Translator
    from='en'
    to={transLang}
    googleApiKey='AIzaSyDvXXS7riq-RvQtmZLLkxkyZoo0LY6XGHc'
  >
    <div className='content' ref={printRef}>
    {/*    <!-- begin breadcrumb --> */}
    <ol className="breadcrumb pull-right">
        <li className="breadcrumb-item">Home</li>
        <li className="breadcrumb-item active">Dashboard</li>
    </ol>
  {/*   <!-- end breadcrumb --> */}

{/*<!-- begin page-header --> */}
    <h1 className="page-header">Dashboard <small><Translate>Building Renovation Survey</Translate></small></h1>
    <div className="row">
        <div className="col-3">
            <div className="d-sm-flex align-items-center mb-3" style={{position: 'relative'}}>
                
                <div id="advance-daterange" className="text-left f-s-12">
                
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: ''}}>

                <button onClick={() => toggleCalendar()} style={{cursor: 'pointer', display: 'block'}} className="btn btn-default btn-block text-left f-s-12">
                {buttonText(startDate, endDate)}
                </button> 

                <button className='btn btn-primary ml-2' onClick={handlePrint}>Download</button>

                <div style={{fontSize: '30px', color: '#ff5b57', cursor: 'pointer', marginLeft: '20px'}}>
               {/*  <i class="fa-regular fa-file-pdf" title='download to pdf'></i> */}
                </div>

                </div>

                <div style={{position:'absolute', left: '0px', zIndex: '100'}} 
                className={displayToggle ? 'display' : 'no-display'}>
                <DateRangePicker
                onChange={item => showSelection(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={true}
                months={2}
                ranges={state}
                direction="horizontal"
              />;
              </div>
                   {/*  <i className="fa fa-caret-down pull-right m-t-2"></i> */}
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- end page-header -->*/}

    <div className="row">
         {/* card showing demographic stats. available bgcolor are bg-red, bg-orange, bg-grey-darker, bg-black-lighter */}
       <Cards title='total survey' data={totalSurvey} bgcolor='bg-red'/>
       <Cards title='male' data={gender.Male} bgcolor='bg-orange'/>
       <Cards title='female' data={gender.Female} bgcolor='bg-grey-darker'/>
       <Cards title='others' data={gender.Others} bgcolor='bg-black-lighter'/>
    </div>


    <div className="reno-div">
    
    <DoughnutChart title='Do you think the C.W. Williams Community Health Center building should be renovated?' data={responseData} options={responseOptions} />
                &nbsp; &nbsp; &nbsp;
    <DoughnutChart title='Age Distribution'  data={ageChartData} options={ageChartoptions} />

    </div>

     

    </div>
    </Translator>
  )
}

export default RenovationDashboard