import axios from 'axios';
import React, { useState } from 'react'
import Header from './Header';
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { useEffect } from 'react';

function English() {

    var defaultThemeColors = Survey
        .StylesManager
        .ThemeColors["default"];
    defaultThemeColors["$main-color"] = "green";
    defaultThemeColors["$main-hover-color"] = "green";
    defaultThemeColors["$text-color"] = "#4a4a4a";
    defaultThemeColors["$header-color"] = "green";

    defaultThemeColors["$header-background-color"] = "";
    defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

    Survey
        .StylesManager
        .applyTheme();

    Survey.surveyStrings.loadingSurvey = "Loading...";
    Survey.surveyStrings.savingData = "Your feedback is being submitted";
    var json = {
        surveyId: 'c4036e6b-16a4-4a8f-9392-c6c2850fbf08'
    }

    //window.survey = new Survey.Model(json);

    /* get query from url */
    let query = new URLSearchParams(window.location.search);
    let trafficSource = query.get('utm_source');
    
    const [fromQrcode, setfromQrcode] = useState(false)


    const sendSuveryResult = (survey) => {
        
        if(trafficSource === 'qrcode'){
            setfromQrcode(true)
        }else{
            setfromQrcode(false)
        }

        console.log(JSON.stringify(survey.data));
        let data = survey.data
        console.log(data)
        axios({
            method: 'post',
            data: data,
            // data: { data: JSON.stringify(survey.data) },
            url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/post_survey_data',
            headers:{
              'Content-Type': 'application/json'
            },
           
        }).then(res => {
            console.log("success")
            console.log(res.data)

            /* log traffic source */
                axios({
                    method: 'post',
                    url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_access_method',
                    data: {
                        'qrcode': fromQrcode
                    }
                }).then(res=>{
                    console.log(res.data)
                })
            
            
        }).catch(err => {
            console.log(err);
        });
    }



  return (
   <>
            <div className="survey--wrapper">
                <Header />
                <Survey.Survey json={json} onComplete={sendSuveryResult} />
            </div>
        </>
  )
}

export default English