import axios from 'axios';
import React, { useState } from 'react'
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Spanish() {

    var defaultThemeColors = Survey
        .StylesManager
        .ThemeColors["default"];
    defaultThemeColors["$main-color"] = "green";
    defaultThemeColors["$main-hover-color"] = "green";
    defaultThemeColors["$text-color"] = "#4a4a4a";
    defaultThemeColors["$header-color"] = "green";

    defaultThemeColors["$header-background-color"] = "";
    defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

    Survey
        .StylesManager
        .applyTheme();

    Survey.surveyStrings.loadingSurvey = "Loading...";
    Survey.surveyStrings.savingData = "Your feedback is being submitted";
    var json = {
        surveyId: 'fd1199e2-54e2-4094-ad67-7cb567f317ab'
    }

    //window.survey = new Survey.Model(json);

    /* get query from url */
    let query = new URLSearchParams(window.location.search);
    let trafficSource = query.get('utm_source');
    
    const [fromQrcode, setfromQrcode] = useState(true)

    const sendSuveryResult = (survey) => {

        if(trafficSource == 'qrcode'){
            setfromQrcode(true)
        }else{
            setfromQrcode(false)
        }
        
        console.log(JSON.stringify(survey.data));
        let data = survey.data
        console.log(data)
        axios({
            method: 'post',
            data: data,
            // data: { data: JSON.stringify(survey.data) },
            url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/post_survey_data',
            headers:{
              'Content-Type': 'application/json'
            },
           
        }).then(res => {
            console.log("success")
            console.log(res.data)

            /* log traffic source */
                axios({
                    method: 'post',
                    url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_access_method',
                    data: {
                        'qrcode': fromQrcode
                    }
                }).then(res=>{
                    console.log(res.data)
                }).then(err=>{
                    console.log(err)
                })
            
            
        }).catch(err => {
            console.log(err);
        });
    }


    return (
        <>
        <Helmet>
        <title>Patient Satisfaction Survey</title>
        <meta name="description" content="CWWCHC Patient Satisfaction Survey" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>

            <div className="survey--wrapper">
                <div style={{ textAlign: 'center', backgroundColor: '' }}>
                    <img src="https://www.cwwilliams.org/wp-content/uploads/2019/07/logo.png" title="CWWCHC Logo" alt="CWWCHC Logo" />
                    <h3>Proporcionar comentarios sobre la atención que recibió</h3>
                    <Link to='/form/satisfaction-survey'><button style={{cursor: 'pointer', border: 'none', padding: '8px 10px', borderRadius: '4px', marginBottom: '4px' }}>Show in English</button></Link>
                </div>
                <Survey.Survey json={json} onComplete={sendSuveryResult} />
            </div>
        </>
    )
}

export default Spanish