import React from 'react'

function NotFound() {
  return (
    <div style={{padding: '30px', color: '#666666'}}>
               <h4>404 | Not Found</h4>
               {/* <h5><Link to='/satisfaction-survey'>View Survey</Link></h5> */}
    </div>  
  ) 
}

export default NotFound