import React, { useEffect, useState, useContext, useRef } from "react";
import "./dashboard.css";
import axios from "axios";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chart.js/auto";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateContext } from "./DateProvider";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { Translator, Translate } from "react-auto-translate";
import { surveyPicker } from "../App";
import { BarChart, DoughnutChart } from "./Charts";
import Cards from "./Cards";

export const dataLabel = {
  display: true,
  color: "black",
  align: "center",
  backgroundColor: "white",
  borderRadius: 4,
  padding: 5,
  font: {
    weight: "bold",
    size: 10,
  },
};

function SatisfactionSurvey() {
  const { transLang } = useContext(surveyPicker);

  Chart.register(ChartDataLabels);
  const [surveyData, setSurveyData] = useState([]);

  const {
    state,
    setState,
    displayToggle,
    setDisplayToggle,
    toggleCalendar,
    showSelection,
    sortedData,
    setSortedData,
    setLoading,
    trafficData,
    setTrafficData,
  } = useContext(DateContext);

  const printRef = useRef(); //Ref for section/div to print

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  /* Calender close button */

  const closeCalender = () => {
    setDisplayToggle(false);
  };

  /* Update button on calendar to update dashboard */

  function Update() {
    console.log(state)

    let sd = state[0].startDate;
    let ed = state[0].endDate;

    let start_date = moment(sd).format("YYYY-MM-DD");
    let end_date = moment(ed).format("YYYY-MM-DD");

    console.log(moment(sd).format("YYYY-MM-DD"), moment(ed).format("YYYY-MM-DD"))
    console.log(start_date, end_date)

    /* Get the surveys by date range */
    axios({
      method: "get",
      headers: { "Content-Type": "application/json" },
      url:
        "https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_by_date_range/" +
        start_date +
        "/" +
        end_date,
    })
      .then((res) => {

        /* request successful, then sort the survey throgh another endpoint to be presentable on the dashboard */
        let data = res.data;
        axios({
          method: "post",
          headers: { "Content-Type": "application/json" },
          url: "https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/sorted_survey_data",
          data: JSON.stringify(data),
        }).then(
          (response) => {
            console.log(response.data);
            setDisplayToggle(!displayToggle);
            setSortedData(response.data);
            setLoading(false);
          },
          (error) => {
            console.log(error.message);
            setLoading(false)
          }
        );
        /* end of data sort request */

        /* get the traffic source */
        axios({
          method: 'get',
          url: 'https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/survey_access_method_analytics_date_range/'+ start_date+'/'+end_date,
          headers: {},
        }).then(res=>{
          console.log(res.data)
          setTrafficData(res.data);
          console.log(trafficData)
        })

      })
      .catch((err) => {
        console.log(err);
      });
  }

  //console.log(state);

  const dateRange = { ...state[0] };

  //console.log(dateRange.startDate);

  const startDate = moment(dateRange.startDate)
    .utcOffset("+01:00")
    .format("YYYY-MM-DD");
  const endDate = moment(dateRange.endDate)
    .utcOffset("+01:00")
    .format("YYYY-MM-DD");

  const totalSurvey = sortedData.total_survey;
  const gender = { ...sortedData.gender_analytics };
  const staff = { ...sortedData.staff_collection_analytics };
  const easeOfAppointment = Object.values({
    ...sortedData.ability_to_get_appointment,
  });
  const hoursWorkForMe = Object.values({
    ...sortedData.Health_center_hours_work_for_me,
  });
  const phoneCallsGetThrough = Object.values({
    ...sortedData.Phone_calls_get_through_easily,
  });
  const race = { ...sortedData.race_analytics };
  const phoneReturned = {
    ...sortedData.Phone_calls_are_returned_within_24_hours,
  };
  const centerLocation = {
    ...sortedData.The_centers_location_is_convenient_for_me,
  };
  const timeInWaitingRoom = { ...sortedData.Time_in_waiting_room };
  const timeInExamRoom = { ...sortedData.Time_in_exam_room };
  const areChargesFair = {
    ...sortedData.Do_you_feel_that_the_fees_CWWCHC_charges_are_fair,
  };
  const areChargesABarrier = {
    ...sortedData.Do_you_feel_that_the_fees_are_a_barrier_to_care,
  };

  const raceLabel = Object.keys(race);
  const raceValue = Object.values(race);
  const age = { ...sortedData.age_analytics };
  const ageLabel = Object.keys(age);
  const ageValue = Object.values(age);
  const staffLabel = Object.keys(staff);
  const staffValue = Object.values(staff);
  const easeValue = Object.values(easeOfAppointment);
  const phoneValue = Object.values(phoneCallsGetThrough);
  const phoneReturnedValue = Object.values(phoneReturned);
  const centerLocationValue = Object.values(centerLocation);
  const timeInWaitingRoomValue = Object.values(timeInWaitingRoom);
  const timeInExamRoomValue = Object.values(timeInExamRoom);
  const chargesFairLabel = Object.keys(areChargesFair);
  const chargesFairValue = Object.values(areChargesFair);
  const areChargesABarrierLabel = Object.keys(areChargesABarrier);
  const areChargesABarrierValue = Object.values(areChargesABarrier);

  /* Label showing on the x axis */
  let stringLabel = ["Poor", "Fair", "Okay", "Excellent"];

  /*Label settings*/

  /* Chart data and options */
  const ageChartData = {
    labels: ageLabel,
    datasets: [
      {
        label: "",
        data: ageValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const ageChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Age distribution",
      },
      datalabels: dataLabel,
    },
  };

  const raceChartData = {
    labels: raceLabel,
    datasets: [
      {
        label: "",
        data: raceValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const raceChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Race Distribution",
      },
      datalabels: dataLabel,
    },
  };

  const chargesFairData = {
    labels: chargesFairLabel,
    datasets: [
      {
        label: "",
        data: chargesFairValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const chargesBarrierData = {
    labels: areChargesABarrierLabel,
    datasets: [
      {
        label: "",
        data: areChargesABarrierValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const byPatientData = {
    labels: staffLabel,
    datasets: [
      {
        data: staffValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const byPatientOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: totalSurvey + 1,

        title: {
          display: true,
          text: "No of Patients",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: dataLabel,
      title: {
        display: false,
        text: "Patients per physician",
      },
      redraw: true,
    },
  };

  const easeChartData = {
    labels: stringLabel,
    datasets: [
      {
        label: "",
        data: easeValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const easeChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: totalSurvey + 1,

        title: {
          display: true,
          text: "No of Patients",
        },
      },
    },
    plugins: {
      legend: false,
      datalabels: dataLabel,
      title: {
        display: false,
        text: "Ease of Appointment",
      },
      redraw: true,
    },
  };

  /* Phone calls get through */
  const phoneChartData = {
    labels: stringLabel,
    datasets: [
      {
        label: "",
        data: phoneValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const phoneReturnedData = {
    labels: stringLabel,
    datasets: [
      {
        label: "",
        data: phoneReturnedValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const timeInWaitingRoomData = {
    labels: stringLabel,
    datasets: [
      {
        label: "",
        data: timeInWaitingRoomValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const timeInExamRoomData = {
    labels: stringLabel,
    datasets: [
      {
        label: "",
        data: timeInExamRoomValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const centerLocationData = {
    labels: stringLabel,
    datasets: [
      {
        label: "",
        data: centerLocationValue,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const phoneChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: totalSurvey + 1,

        title: {
          display: true,
          text: "No. of Patients",
        },
      },
    },
    plugins: {
      legend: false,
      datalabels: dataLabel,
      title: {
        display: false,
        text: "Phone Calls Get Through",
      },
      redraw: true,
    },
  };

  const chartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: totalSurvey + 1,

        title: {
          display: true,
          text: "No. of Patients",
        },
      },
    },
    plugins: {
      legend: false,
      datalabels: dataLabel,
      title: {
        display: false,
        text: "",
      },
      redraw: true,
    },
  };

  const buttonText = (startDate, endDate) => {
    if (startDate == endDate) {
      return "Today";
    } else {
      return startDate + " - " + endDate;
    }
  };

  return (
    <Translator
      from="en"
      to={transLang}
      googleApiKey="AIzaSyDvXXS7riq-RvQtmZLLkxkyZoo0LY6XGHc"
    >
      <div id="content" className="content" ref={printRef}>
        {/*    <!-- begin breadcrumb --> */}
        <ol className="breadcrumb pull-right">
          <li className="breadcrumb-item">
            <a>Home</a>
          </li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ol>
        {/*   <!-- end breadcrumb -->
    <!-- begin page-header --> */}
        <h1 className="page-header">
          Dashboard{" "}
          <small>
            <Translate>Patient Satisfaction Survey</Translate>
          </small>
        </h1>
        <div className="row">
          <div className="col-3">
            <div
              className="d-sm-flex align-items-center mb-3"
              style={{ position: "relative" }}
            >
              {/* Date picker */}
              <div id="advance-daterange" className="text-left f-s-12">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "",
                  }}
                >
                  <button
                    onClick={() => toggleCalendar()}
                    style={{ cursor: "pointer", display: "block" }}
                    className="btn btn-default btn-block text-left f-s-12"
                  >
                    {buttonText(startDate, endDate)}
                  </button>

                  <button
                    className="btn btn-primary ml-2"
                    onClick={handlePrint}
                    title="Export current view to PDF"
                  >
                    Export to PDF
                  </button>

                  <div
                    style={{
                      fontSize: "30px",
                      color: "#ff5b57",
                      cursor: "pointer",
                      marginLeft: "20px",
                    }}
                  >
                    {/*  <i class="fa-regular fa-file-pdf" title='download to pdf'></i> */}
                  </div>
                </div>

                <div
                  style={{ position: "absolute", left: "0px", zIndex: "100" }}
                  className={displayToggle ? "display" : "no-display"}
                >
                  <DateRangePicker
                    onChange={(item) => {
                      showSelection(item);
                      console.log(state);
                    }}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                  />

                  <div className="bg-light p-2 text-right">
                    <button
                      className="btn btn-secondary"
                      onClick={() => toggleCalendar()}
                    >
                      <span style={{ fontSize: "13px" }} >Close</span>
                    </button>
                    &nbsp; &nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => Update()}
                    >
                      <span style={{ fontSize: "13px" }}>Update</span>
                    </button>
                  </div>
                </div>
                {/*  <i className="fa fa-caret-down pull-right m-t-2"></i> */}
                <span></span>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end page-header -->
    
    <!-- begin row --> 
    
    Stat cards (total survey, male...)*/}

        <div className="row">
          {/* card showing demographic stats. available bgcolor are bg-red, bg-orange, bg-grey-darker, bg-black-lighter */}
          <Cards title="total survey" data={totalSurvey} bgcolor="bg-red" />
          <Cards title="male" data={gender.Male} bgcolor="bg-orange" />
          <Cards title="female" data={gender.Female} bgcolor="bg-grey-darker" />
          <Cards
            title="others"
            data={gender.Others}
            bgcolor="bg-black-lighter"
          />
          <Cards title="From Qrcode" data={trafficData.true_count | 0} bgcolor="bg-purple-like" />
          <Cards title="From Tablet" data={trafficData.false_count | 0} bgcolor="bg-blue" />
        </div>
        {/*    <!-- end row -->


    <!-- begin row --> */}
        <div className="row">
          {/* 2 divs placed side-by-side col-8 the widest and the other */}
          {/*  <!-- begin col-8 mostly containing the bar charts--> */}
          <div className="col-lg-8">
            <BarChart
              title="PATIENT PER PHYSICIAN"
              data={byPatientData}
              options={byPatientOptions}
            />

            <BarChart
              title="Phone Calls Get Through"
              data={phoneChartData}
              options={phoneChartoptions}
            />

            <BarChart
              title="Phone call are returned within 24hrs"
              data={phoneReturnedData}
              options={phoneChartoptions}
            />

            <BarChart
              title="Ease of Appointment"
              data={easeChartData}
              options={easeChartoptions}
            />

            <BarChart
              title="The center location is convenient for me"
              data={centerLocationData}
              options={chartoptions}
            />

            <BarChart
              title="Time In Waiting Room"
              data={timeInWaitingRoomData}
              options={chartoptions}
            />

            <BarChart
              title="Time in exam room"
              data={timeInExamRoomData}
              options={chartoptions}
            />
          </div>

          {/* <!-- end col-8 -->


        <!-- begin col-4 --> */}
          <div className="col-lg-4">
            {/*  <!-- begin panel --> */}
            <DoughnutChart
              title="Age Distribution"
              data={ageChartData}
              options={ageChartoptions}
            />

            <DoughnutChart
              title="Race Distribution"
              data={raceChartData}
              options={raceChartoptions}
            />

            <DoughnutChart
              title="Are C.W. Williams Community Health Center charges fair?"
              data={chargesFairData}
              options={raceChartoptions}
            />

            <DoughnutChart
              title="Are charges a barrier to care"
              data={chargesBarrierData}
              options={raceChartoptions}
            />

            {/* <!-- end panel --> */}
          </div>
          {/* <!-- end col-4 --> */}
        </div>
        {/*  <!-- end row --> */}
      </div>
    </Translator>
  );
}

export default SatisfactionSurvey;
