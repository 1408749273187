import React, { useContext, useState } from "react";
import "./style.css";
import { surveyPicker } from "../App";

function Header() {
  const { satisfactionSurvey, setSatisfactionSurvey, transLang, setTransLang } =
    useContext(surveyPicker);

  const renovationSurvey = () => {
    setSatisfactionSurvey(false);
  };

  const SatisfactionSurvey = () => {
    setSatisfactionSurvey(true);
  };

  const setLang = (lang) => {
    setTransLang(lang);
  };

  const setToEs = () => {
    setTransLang("es");
  };

  return (
    <div
      id="header"
      className="header navbar-default"
      style={{ display: "flex", alignItems: "center", width: "100%" }}
    >
      {/*   begin navbar-header */}

      <div className="navbar-header">
        <nav class="navbar navbar-light bg-light" style={{ width: "210px" }}>
          <a class="navbar-brand" href="/core">
            Survey Analytics
          </a>
        </nav>
      </div>

      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* end navbar-header */}

          <div
            style={{
              paddingTop: "0px",
              marginRight: "0px",
              float: "left",
              borderRight: "1px solid #ddd",
              borderLeft: "1px solid #ddd",
            }}
          >
            <ul className="navbar-nav navbar-right">
              <li className="dropdown navbar-user">
                <a href="" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-lg fa-chart-pie m-r-5"></i>
                  <span className="d-none d-md-inline">Select Survey</span>{" "}
                  <b className="caret"></b>
                </a>
                <div className="dropdown-menu dropdown-menu-left">
                  <a
                    onClick={SatisfactionSurvey}
                    style={{ cursor: "pointer" }}
                    className="dropdown-item"
                  >
                    Patient Satisfaction Survey
                  </a>
                  <a
                    onClick={renovationSurvey}
                    style={{ cursor: "pointer" }}
                    className="dropdown-item"
                  >
                    Building Renovation Survey
                  </a>
                  <div className="dropdown-divider"></div>
                  <a href="all_branches.php" className="dropdown-item">
                    View All Branches
                  </a>
                </div>
              </li>
            </ul>
          </div>

          {/* begin header-nav */}

          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <div>
              <span>Select Language:</span>
              <select
                className="lang-select"
                onChange={(e) => setLang(e.target.value)}
              >
                <option value="en">English</option>
                <option value="es">Spanish</option>
              </select>
            </div>

            <ul className="navbar-nav navbar-right">
              <li className="dropdown navbar-user">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <span className="d-none d-md-inline">
                    {typeof localStorage !== "undefined" &&
                    localStorage.getItem("name")
                      ? localStorage.getItem("name")
                      : "admin"}
                  </span>{" "}
                  <b className="caret"></b>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a href="/login" className="dropdown-item">
                    Log Out
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/*  end header navigation right
         */}
      </div>
    </div>
  );
}

export default Header;
