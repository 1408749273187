import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import './question.css'
import { useReactToPrint } from "react-to-print";

function SurveyQuestions() {

    const { rid } = useParams();
    const { sid } = useParams();
    const [surveyAnswer, setSurveyAnswer] = useState({});
    const [name, setName] = useState('')
    const printRef = useRef();

    useEffect(()=>{
        axios({
            method: 'get',
            url: `https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/patient_survey_question_answer/${rid}/${sid}`,
        }).then(res=>{
            setSurveyAnswer(res.data);
            setName(res.data.contact['Name:']);
            console.log(res.data);
        })
    }, [])


    const handlePrint = useReactToPrint({
        content: () => printRef.current,
      });

    const questions = (
        <>
        <section ref={printRef}>
        <h6>Survey {sid} by {name || 'Anonymous'}</h6>
            <table id="data-table-responsive" className="table table-bordered" style={{cursor: "pointer"}}>
            <thead>
                <tr>
                    <th className="text-nowrap">Questions</th>
                    <th className="text-nowrap">Answer</th>
                </tr>
            </thead>
            
            <tbody style={{cursor: "pointer"}}>
                <tr><td>Age</td> <td>{surveyAnswer.Age}</td></tr>
                <tr className=''><td>Gender</td><td>{surveyAnswer.Gender}</td></tr>
                <tr className=''><td>Service Received</td><td>{surveyAnswer.Service_Received}</td></tr>
                <tr className=''><td>Staff</td><td>{surveyAnswer.Staff}</td></tr>
                <tr className=''><td>Ability to get appointment</td><td>{surveyAnswer.ability_to_get_appointment}</td></tr>
                <tr className=""><td>Are you aware of our sliding scale fee program</td> <td>{surveyAnswer.Are_you_aware_of_our_sliding_scale_fee_program}</td></tr>
                <tr className=""><td>At todays visit did someone talk to you about your health or dental goals</td> <td>{surveyAnswer.At_todays_visit_did_someone_talk_to_you_about_your_health_or_dental_goals}</td></tr>
                <tr className=""><td>At todays visit were you asked if you had been in the hospital recently</td> <td>{surveyAnswer.At_todays_visit_were_you_asked_if_you_had_been_in_the_hospital_recently}</td></tr>
                <tr className=""><td>Did the front desk staff behave in a friendly and helpful manner</td> <td>{surveyAnswer.Did_the_front_desk_staff_behave_in_a_friendly_and_helpful_manner}</td></tr>

                <tr className=""><td>Do you feel that the fees are a barrier to care</td> <td>{surveyAnswer.Do_you_feel_that_the_fees_are_a_barrier_to_care}</td></tr>

                <tr 
                className=""><td>Do you feel that you could receive most of your care here</td> <td>{surveyAnswer.Do_you_feel_that_you_could_receive_most_of_your_care_here}</td></tr>

                <tr className=""><td>Health center hours work for me</td> <td>{surveyAnswer.Health_center_hours_work_for_me}</td></tr>

                <tr className=""><td>If you need other services did we help you find those services</td> <td>{surveyAnswer.If_you_need_other_services_did_we_help_you_find_those_services}</td></tr>

                <tr className=""><td>If you used those services were they helpful</td> <td>{surveyAnswer.If_you_used_those_services_were_they_helpful}</td></tr>

                <tr className=""><td>Phone calls are returned within 24 hours</td> <td>{surveyAnswer.Phone_calls_are_returned_within_24_hours}</td></tr>

                <tr className=""><td>Phone calls get through easily</td> <td>{surveyAnswer.Phone_calls_get_through_easily}</td></tr>

                <tr className=""><td>The centers location is convenient for me</td> <td>{surveyAnswer.The_centers_location_is_convenient_for_me}</td></tr>

                <tr className=""><td>Time in exam room</td> <td>{surveyAnswer.Time_in_exam_room}</td></tr>

                <tr className=""><td>Time in waiting room</td> <td>{surveyAnswer.Time_in_waiting_room}</td></tr>

                <tr className=""><td>Waiting for test result</td> <td>{surveyAnswer.Waiting_for_test_result}</td></tr>

                <tr className=""><td>Waiting for tests to be performed</td> <td>{surveyAnswer.Waiting_for_tests_to_be_performed}</td></tr>

                <tr className=""><td>What additional services would you like C.W. Williams Community Health Center to offer</td> <td>{surveyAnswer.What_additional_services_would_you_like_CWWCHC_to_offer}</td></tr>

                <tr className=""><td>Would you send your friends and family here</td> <td>{surveyAnswer.Would_you_send_your_friends_and_family_here}</td></tr>

                <tr className=""><td>Comments</td> <td>{surveyAnswer.comments}</td></tr>
            </tbody>
            </table>
            </section>
            </>
    )

  return (
    <div id="content" className="content">
        {/* <!-- begin breadcrumb --> */}
        <ol className="breadcrumb pull-right">
            <li className="breadcrumb-item"><a href="/">Home</a></li>
            <li className="breadcrumb-item"><a onClick={()=>window.history.back()}>Respondent</a></li>
            <li className="breadcrumb-item"><a href="#">Questionnaire</a></li>
        </ol>
        {/* <!-- end breadcrumb -->*/}

        <div style={{width: '60%'}} className="mt-5 question">
        {questions}


            <div>
                <button className='btn btn-warning btn-md mr-2' onClick={handlePrint}>Print</button>

                <button className='btn btn-danger btn-md' onClick={handlePrint}>Download as PDF</button>
            </div>
        </div>
    </div>
  )
}

export default SurveyQuestions