import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-auto-translate/lib/commonjs'

const Cards = ({title, data, bgcolor}) => {
  return (
    <div className="col-lg-3 col-md-6">
            <div className={`widget widget-stats bg-red ${bgcolor}`}>
                <div className="stats-icon"><i className="fa fa-desktop"></i></div>
                <div className="stats-info">
                    <h4><Translate>{title.toUpperCase()}</Translate></h4>
                     <p>{data ? data : 0}</p>	
	
                </div>
                {/* <div className="stats-link" style={{visibility: 'hidden'}}>
                    <a href="#">View Respondents <i className="fa fa-arrow-alt-circle-right"></i></a>
                </div> */}
            </div>
        </div>
  )
}

Cards.propTypes = {
    //title: PropTypes.string.isRequired
}

export default Cards
