import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Dashboard from './components/Dashboard';
import Respondents from './components/Respondents';
import { DateProvider } from './components/DateProvider';
import { RenovationDateProvider } from './components/RenovationDateProvider';
import RespondentSurvey from './components/RespondentSurvey';
import SurveyQuestions from './components/SurveyQuestions';
import Login from './components/Login';
import Layout from './components/Layout';
import Authorizer from './components/Authorizer';
import QRgenerator from './components/QRgenerator';
import RenovationSurvey from './components/form/RenovationSurvey';
import { createContext, useState } from 'react';
import { ThankYou } from './components/form/ThankYou';
import Spanish from './components/form/Spanish'
import English from './components/form/English';
import NotFound from './components/form/NotFound'


export const surveyPicker = createContext()

function App() {

  
   
  const [satisfactionSurvey, setSatisfactionSurvey] = useState(true);
  const [transLang, setTransLang] = useState('en');

  return (
    <div className="App">
      {/* <BrowserRouter> */}
      {/* <BrowserRouter basename="/cww"> */}
      <BrowserRouter>
        <DateProvider>
          <RenovationDateProvider>
            <surveyPicker.Provider
              value={{
                satisfactionSurvey,
                setSatisfactionSurvey,
                transLang,
                setTransLang,
              }}
            >
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/form/building-survey"
                  element={<RenovationSurvey />}
                />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/form/satisfaction-survey" element={<English />} />
                <Route
                  path="/form/satisfaction-survey/spanish"
                  element={<Spanish />}
                />
                <Route path="*" element={<NotFound />} />
                <Route element={<Authorizer />}>
                  <Route element={<Layout />}>
                    <Route path="/core" element={<Dashboard />} />
                    <Route path="/core/dashboard" element={<Dashboard />} />
                    <Route path="/core/respondents" element={<Respondents />} />
                    <Route path="/core/qrcode" element={<QRgenerator />} />
                    <Route
                      path="/core/respondent-surveys/:name/:id"
                      element={<RespondentSurvey />}
                    />
                    <Route
                      path="/core/respondent-survey/:rid/:sid"
                      element={<SurveyQuestions />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Route>
              </Routes>
            </surveyPicker.Provider>
          </RenovationDateProvider>
        </DateProvider>
      </BrowserRouter>
    </div>
  );
  
};

export default App;
