import axios from 'axios'
import React, { useState, useEffect } from 'react'
import './respondents.css'

function Respondents() {
  const [respondentData, setRespondentData] = useState([]);

  useEffect(() => {
    axios({
      method: "get",
      url: "https://cww-survey-engine-backend-7217cd424085.herokuapp.com/api/v1/respondents",
    }).then((res) => {
      setRespondentData(res.data);
      console.log(res.data);
    });
  }, []);

  const respondentsRow = respondentData.map((res) => {
    return (
      <tr
        className="odd gradeX"
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location = `/core/respondent-surveys/${res.contact["Name:"]}/${res.respondent_id}`;
        }}
      >
        <td>{res.contact["Name:"]}</td>
        <td>{res.contact["Phone_Number:"]}</td>
        <td>{res.date_added}</td>
      </tr>
    );
  });

  //console.log(respondentsRow);

  return (
    <div id="content" className="content">
      {/* <!-- begin breadcrumb --> */}
      <ol className="breadcrumb pull-right">
        <li className="breadcrumb-item">
          <a href="/core">Home</a>
        </li>
        <li className="breadcrumb-item">
          <a href="#">Respondents</a>
        </li>
      </ol>
      {/* <!-- end breadcrumb -->
        <!-- begin page-header --> */}
      {/*         <h1 className="page-header">All Respondents</h1>
       */}{" "}
      {/* <!-- end page-header --> */}
      {/*  <!-- begin col-10 --> */}
      <div className="col-lg-12 mt-5">
        {/*  <!-- begin panel --> */}
        <div className="card">
          {/* <!-- begin panel-heading --> */}
          <div className="card-header">
            <h4 className="m-b-0 p-t-5 p-b-5">Respondents</h4>
          </div>
          {/* <!-- end panel-heading -->
                        <!-- begin panel-body --> */}
          <div className="card-body">
            <table
              id="data-table-responsive"
              className="table table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th className="text-nowrap">Respondent Name</th>
                  {/* <th className="text-nowrap">Email Address</th> */}
                  <th className="text-nowrap">Phone</th>
                  <th className="text-nowrap">Date</th>
                </tr>
              </thead>
              <tbody>{respondentsRow}</tbody>
            </table>
          </div>
          {/* <!-- end panel-body --> */}
        </div>
        {/* end panel */}
      </div>
    </div>
  );
}

export default Respondents;